/*
 * vim: ts=4 sw=4 expandtab
 */

@import "jekyll-theme-hacker";
@import "/assets/css/fonts/InputMonoCompressed/fonts.css";
@import "/assets/css/fonts/OutputSans/fonts.css";

:root
{
    --font-body: 'OutputSans', 'InputMonoCompressed', 'Input Mono Regular', Input, 'Envy Code R', Inconsolata, 'Bitstream Vera Sans Mono', 'Lucida Console', monospace;
    --font-code: 'InputMonoCompressed', 'Input Mono Regular', Input, 'Envy Code R', Inconsolata, 'Bitstream Vera Sans Mono', 'Lucida Console', monospace;

    --color-body: #e5e5e5;
    --color-secondary: #eaeaea;
    --color-accent: #b5e853;
    --color-background: #151515;
}

*
{
    box-sizing: border-box;
}

body
{
    font-family: var(--font-body);
    font-feature-settings: "zero" on;
    min-height: calc(100vh - 20px);
}

header h1,
#main_content h1 + h2,
.script-name,
pre, code
{
    font-family: var(--font-code);
}

i, cite, em, var, address, dfn
{
    font-feature-settings: "ss05" on; /* Output Sans stylistic set 5: alternate a. */
}

header h1
{
    margin: 0;
    font-weight: 900;
    font-size: 2.2rem;
    font-feature-settings: "zero" on;
}

header h1:before
{
    content: '';
}

header img
{
    image-rendering: crisp-edges;
    float: left;
    width: calc(3.3rem + 20px);
    height: auto;
    margin-right: 0.5rem;
}

h1, h2, h3, h4, h5, h6
{
    font-family: inherit;
    font-feature-settings: "ss01" on;
}

h1 small
{
    color: var(--color-secondary);
    font-weight: 200;
}

h1 small:before
{
    content: ' — ';
}

#main_content h1 + h2
{
    color: var(--color-secondary);
    margin-top: -1.5rem;
    font-weight: 300;
    font-feature-settings: "ss01" off;
}

ul li
{
    list-style-image: none;
    list-style: none;
    position: relative;
}

ul li:before
{
    content: '>>';
    color: var(--color-accent);
    margin: 0 0 0 -1.5em;
}

.post-list > li {
    padding-right: 4rem;
}

.post-list > li > h2:first-child {
    display: inline;
    clear: both;
}

.post-date {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    text-align: center;
}

.post-day {
    display: block;
    font-size: 0.9rem;
}

.post-year {
    display: block;
    font-size: 1.1rem;
}

.btn
{
    display: inline-block;
    background: var(--color-accent);
    padding: 8px 18px;
    border-radius: 0.5rem;
    border: 2px solid var(--color-accent);
    color: var(--color-background);
    font-family: var(--font-body);
    font-size: 1.3rem;
    font-weight: normal;
    text-decoration: none;
    text-shadow: none;
    line-height: 1.1rem;
}

.btn:hover
{
    background: var(--color-background);
    color: var(--color-accent);
}

.btn small
{
    font-size: 0.8rem;
}

.home-image {
    text-align: center;
}

.home-image img {
    width: auto;
    max-width: 1000px;
}

.snippet > header
{
    background: var(--color-accent);
    color: var(--color-background);
    margin: 0;
    padding: 0 0.5rem;
    font-weight: 600;
}

.snippet > header > a
{
    color: var(--color-background);
    text-decoration: dotted;
    font-size: 0.9rem;
    line-height: 1.5rem;
    font-weight: 400;
    float: right;
}

.snippet > pre
{
    margin-top: 0;
}

#search-input
{
    background: transparent;
    width: 100%;
    font-size: 2rem;
    border: 1px solid var(--color-accent);
    border-radius: 0.5rem;
    color: var(--color-body);
    padding: 0.5rem;
    font-family: var(--font-code);
}

/* Typing animation */
.faketerm
{
    position: relative;
}

.faketerm-text
{
    -webkit-animation: typing-text 10s steps(8, end) infinite alternate;
    animation: typing-text 10s steps(8, end) infinite alternate;
}

.faketerm-cursor
{
    position: absolute;
    top: 0;
    bottom: 0;
    right: -.5em;
    width: 0;
    background: var(--color-background);
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-left: .5em solid;
    -webkit-animation: typing-cursor 10s steps(8, end) infinite alternate, blinking 1s step-end infinite;
    animation: typing-cursor 10s steps(8, end) infinite alternate, blinking 1s step-end infinite;
}

@-webkit-keyframes typing-cursor
{
    from,to { right: 3.5em; }
    5% { right: 3.5em; }
    15% { right: -.5em; }
    25% { right: -.5em; }
    50% { right: -.5em; }
    75% { right: 3.5em; }
}

@keyframes typing-cursor
{
    from { right: 3.5em; }
    5% { right: 3.5em; }
    15% { right: -.5em; }
    to { right: -.5em; }
}

@-webkit-keyframes typing-text
{
    from,to { clip-path: inset(0 4em 0 0); }
    5%      { clip-path: inset(0 4em 0 0); }
    15%     { clip-path: inset(0); }
    25%     { clip-path: inset(0); }
    50%     { clip-path: inset(0); }
    75%     { clip-path: inset(0 4em 0 0); }
}

@keyframes typing-text
{
    from { clip-path: inset(0 4em 0 0); }
    5%   { clip-path: inset(0 4em 0 0); }
    15%  { clip-path: inset(0); }
    to   { clip-path: inset(0); }
}

@-webkit-keyframes blinking
{
    from, to { border-color: transparent; }
    50% { border-color: var(--color-secondary); }
}

@keyframes blinking
{
    from, to { border-color: transparent; }
    50% { border-color: var(--color-secondary); }
}
